import React, { Component } from 'react'
import Layout from "../components/layout";
import { graphql } from 'gatsby'
import { get, first } from 'lodash'
import { getDataForLanguage} from '../utils/getDataForLanguage'

export class PageNotFound extends Component {
    render() {
    
        const allPageData = get(this, "props.data.allContentfulSiteInformations.edges", '');
        let pageData = first(getDataForLanguage(allPageData.map(item => item.node), process.env.COUNTRY));

        return (
            <Layout 
                title={get(pageData, 'pageNotFoundMetaTitle','')} 
                description={get(pageData, 'pageNotFoundMetaDescription','')}
                pathName="/"
            >
                <div className="basic-page">
                    <div className="container" id="maincontent">
                        <h1 className="page-title text-center">404 - Page Not Found</h1>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default PageNotFound

export const pageQuery = graphql`
    query SiteInfoFor404Page {
        allContentfulSiteInformations {
            edges {
                node {
                    pageNotFoundMetaTitle
                    pageNotFoundMetaDescription
                    node_locale
                    contentful_id
                }
            }
        }
    }
`
